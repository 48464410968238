import React from 'react';
import { graphql } from 'gatsby';

import Badge from '../components/shared/Badge';
import Header from '../components/Header';
import Layout from '../components/Layout';

import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { richTextParse } from '../utils/utility-functions';

import delimiter2 from '../assets/graphics/flatwave-delimiter-2.png';
import delimiter3 from '../assets/graphics/flatwave-delimiter-3.png';

const Top = ({ topContent }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-12 center-sm">
          { richTextParse(topContent[0]) }
        </div>
      </div>
    </div>
  )
};

const Middle = ({ middleContent }) => {
  return (
    <div className="middle">
      <div className="waves waves--mirrored"></div>
      <img className="delimiter-top" src={ delimiter2 } alt={ "Flatwave delimiter" } />
      <div className="container">
        <div className="row">
          <div className="col-12 center-sm contentful-white">
            { richTextParse(middleContent[0]) }
          </div>
        </div>
      </div>
      <img className="delimiter-bottom" src={ delimiter3 } alt={ "Flatwave delimiter" } />
    </div>
  )
};

const Team = ({ employees }) => (
  <div className="container">
    <div className="row">
      <div className="col-12 center-sm">
        <h2 className="team-heading">Team</h2>
      </div>
    </div>
    <div className="row">
      {
        employees.map(employee => (
          <Badge
            key={ employee.node.name }
            icon={ employee.node.icon.file.url }
            title={ employee.node.name }
            quote={ employee.node.quote }
          />
        ))
      }
    </div>
  </div>
);

const AboutPage = ({ data }) => (
  <Layout>
    <div>
      <Helmet title={ `${data.contentfulAboutUsContent.title} | flatwave.se` } />
      <Header title={ data.contentfulAboutUsContent.title } />
      <Top topContent={ data.allContentfulAboutUsContentSectionTopRichTextNode.nodes } />
      <Middle middleContent={ data.allContentfulAboutUsContentSectionMiddleRichTextNode.nodes } />
      <Team employees={ data.allContentfulEmployee.edges } />
    </div>
  </Layout>
);

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutUsPageQuery = graphql`
query AboutUsPageQuery {
  contentfulAboutUsContent {
    title
  }
  allContentfulEmployee {
    edges {
      node {
        id
        name
        quote
        icon {
          file {
            url
          }
        }
      }
    }
  }
  allContentfulAboutUsContentSectionTopRichTextNode {
    nodes {
      nodeType
      content {
        nodeType
        content {
          value
          nodeType
          marks {
            type
          }
        }
      }
    }
  }
  allContentfulAboutUsContentSectionMiddleRichTextNode {
    nodes {
      nodeType
      content {
        nodeType
        content {
          value
          nodeType
          marks {
            type
          }
        }
      }
    }
  }
}
`;
